import { Api } from "../api.js";
import { Stream } from "../utils/streams.js";
import { error, sleep } from "../utils/utils.js";

const servers = ["https://fusme.link/", "https://jfper.link/", "https://uxert.link/", "https://yrkde.link/"];

export type PopcornTorrent = {
    url: string;
    title: string;
    seed: number;
    peer: number;
    quality: string;
    size: string;
    filesize?: string;
};

export type PopcornTorrentList = {
    "480p"?: PopcornTorrent;
    "720p"?: PopcornTorrent;
    "1080p"?: PopcornTorrent;
    "2160p"?: PopcornTorrent;
};

export type PopcornImages = {
    poster: string;
    fanart: string;
    banner: string;
};

export type PopcornMovie = {
    _id: string;
    imdb_id: string;
    tmdb_id: number;
    title: string;
    year: string;
    synopsis: string;
    runtime: string;
    released: number;
    original_language: string;
    torrents: {
        [k: string]: PopcornTorrentList;
    };
    trailer?: string;
    genres: string[];
    images: PopcornImages;
    rating: {
        percentage: number;
    };
};

export type PopcornShow = {
    _id: string;
    imdb_id: string;
    tmdb_id: number;
    tvdb_id: string;
    title: string;
    year: string;
    num_seasons: number;
    images: PopcornImages;
    rating: {
        percentage: number;
    };
};

export type PopcornShowEpisode = {
    season: number;
    episode: number;
    first_aired: number;
    title: string;
    overview: string;
    tvdb_id: number;
    torrents: {
        [k: string]: PopcornTorrent[];
    };
};

export type PopcornShowDetail = {
    _id: string;
    imdb_id: string;
    tmdb_id: number;
    tvdb_id: string;
    title: string;
    year: string;
    synopsis: string;
    original_language: string;
    num_seasons: number;
    images: PopcornImages;
    rating: {
        percentage: number;
    };
    last_updated: number;
    status: string;
    genres: string[];
    episodes: PopcornShowEpisode[];
};

export type PopcornGenre =
    | "all"
    | "action"
    | "adventure"
    | "animation"
    | "comedy"
    | "crime"
    | "disaster"
    | "documentary"
    | "drama"
    | "eastern"
    | "family"
    | "fan-film"
    | "fantasy"
    | "film-noir"
    | "history"
    | "holiday"
    | "horror"
    | "indie"
    | "music"
    | "mystery"
    | "none"
    | "road"
    | "romance"
    | "science-fiction"
    | "short"
    | "sports"
    | "sporting-event"
    | "suspense"
    | "thriller"
    | "tv-movie"
    | "war"
    | "western";
export type PopcornMovieSort = "last_added" | "rating" | "title" | "trending" | "year";
export type PopcornShowSort = "name" | "rating" | "trending" | "updated" | "year";
export type PopcornOrder = "asc" | "desc";

class RequestLimiter {
    private maxConcurrentRequests: number;
    private currentRequests = 0;
    private queue: (() => void)[] = [];

    constructor(maxConcurrentRequests: number) {
        this.maxConcurrentRequests = maxConcurrentRequests;
    }

    async fetch<T>(url: string): Promise<T | Error> {
        if (this.currentRequests >= this.maxConcurrentRequests) {
            // Store a function that resolves a promise in the queue
            await new Promise<void>((resolve) => this.queue.push(resolve));
        }

        this.currentRequests++;
        try {
            const response = await Api.proxyJson<T>(url);
            return response;
        } finally {
            this.currentRequests--;
            // Execute the next function in the queue, if available
            if (this.queue.length > 0) {
                const next = this.queue.shift();
                if (next) next(); // This now correctly calls a no-argument function
            }
        }
    }
}

export class Popcorn {
    static serverIndex = 0;
    static limiter = new RequestLimiter(5);

    static async movies(
        query?: string,
        sort?: PopcornMovieSort,
        order?: PopcornOrder,
        genre?: PopcornGenre,
        cursor = "1",
        limit = 25
    ): Promise<PopcornMovie[] | Error> {
        try {
            if (genre == "all") genre = undefined;
            const params = [
                query ? `keywords=${encodeURIComponent(query)}` : "",
                sort ? `sort=${sort}` : "",
                order ? `order=${order == "asc" ? 1 : -1}` : "",
                genre ? `genre=${encodeURIComponent(genre)}` : "",
                limit ? `limit=${limit}` : "50",
            ].filter((param) => param.length > 0);
            const url = servers[this.serverIndex] + `movies/${cursor}?` + params.join("&");
            this.serverIndex++;
            if (this.serverIndex > servers.length - 1) this.serverIndex = 0;
            return await exponentialBackoffFetch(() => this.limiter.fetch<PopcornMovie[]>(url), "Couldn't list movies");
        } catch (e) {
            return error("Couldn't list movies", e);
        }
    }

    static async movie(id: string, noLimiter = false): Promise<PopcornMovie | Error> {
        try {
            const url = servers[this.serverIndex] + `movie/${id}`;
            this.serverIndex++;
            if (this.serverIndex > servers.length - 1) this.serverIndex = 0;
            return await (noLimiter ? await Api.proxyJson<PopcornMovie>(url) : this.limiter.fetch<PopcornMovie>(url));
        } catch (e) {
            return error("Couldn't load show", e);
        }
    }

    static async shows(
        query?: string,
        sort?: PopcornShowSort,
        order?: PopcornOrder,
        genre?: PopcornGenre,
        cursor = "1"
    ): Promise<PopcornShow[] | Error> {
        try {
            if (genre == "all") genre = undefined;
            const params = [
                query ? `keywords=${encodeURIComponent(query)}` : "",
                sort ? `sort=${sort}` : "",
                order ? `order=${order == "asc" ? 1 : -1}` : "",
                genre ? `genre=${encodeURIComponent(genre)}` : "",
            ].filter((param) => param.length > 0);
            const url = servers[this.serverIndex] + `shows/${cursor}?` + params.join("&");
            this.serverIndex++;
            if (this.serverIndex > servers.length - 1) this.serverIndex = 0;
            return await exponentialBackoffFetch(() => this.limiter.fetch<PopcornShow[]>(url), "Couldn't list shows");
        } catch (e) {
            return error("Couldn't list shows", e);
        }
    }

    static async show(id: string, noLimiter = false): Promise<PopcornShowDetail | Error> {
        try {
            const url = servers[this.serverIndex] + `show/${id}`;
            this.serverIndex++;
            if (this.serverIndex > servers.length - 1) this.serverIndex = 0;
            return await (noLimiter ? await Api.proxyJson<PopcornShowDetail>(url) : this.limiter.fetch<PopcornShowDetail>(url));
        } catch (e) {
            return error("Couldn't load show", e);
        }
    }
}

export async function exponentialBackoffFetch<V>(fetcher: () => Promise<V | Error>, errorMessage: string) {
    let sleepTime = 250;
    let tries = 0;
    while (tries < 10) {
        const items = await fetcher();
        if (items instanceof Error) {
            await sleep(sleepTime);
            sleepTime *= 2;
            tries++;
            continue;
        }
        sleepTime = 250;
        tries = 0;
        return items;
    }
    console.error(errorMessage);
    return new Error("Couldn't fetch");
}

async function crawlPopcornCollection<T, I>(
    collection: string,
    fetcher: (cursor: number) => Promise<T[] | Error>,
    itemTransform?: (item: T) => Promise<I | Error>
) {
    console.log(`Crawing collection '${collection}'`);
    let cursor = 1;
    const allItems: T[] = [];
    const allTransformedItems: I[] = [];
    const start = performance.now();
    let errors = 0;
    while (true) {
        const items = await exponentialBackoffFetch<T[]>(() => fetcher(cursor), "Couldn't crawl page " + cursor);
        if (items instanceof Error) {
            errors++;
            break;
        }
        if (items.length == 0) {
            break;
        }
        if (itemTransform) {
            const transform = itemTransform;
            for (const item of items) {
                const transformedItem = await exponentialBackoffFetch<I>(() => transform(item), "Couldn't crawl item detail");
                if (transformedItem instanceof Error) {
                    errors++;
                    continue;
                }
                allTransformedItems.push(transformedItem);
            }
        }
        allItems.push(...items);
        if (allItems.length % 100 == 0) {
            console.log(`Fetched ${allItems.length} items, ${((performance.now() - start) / 1000).toFixed(2) + " secs"}`);
        }
        cursor++;
    }
    console.log(`Crawled ${cursor} pages, ${allItems.length} ${collection}`);
    if (errors > 0) {
        console.error(`Couldn't crawl Popcorn items, ${errors} errors`);
    } else {
        console.log(`Updated ${collection} database, took ` + ((performance.now() - start) / 1000).toFixed(2) + " secs");
    }
    return { allItems, allTransformedItems };
}

async function crawlPopcorn(processResult: (result: { movies: PopcornMovie[]; shows: PopcornShowDetail[] } | Error) => Promise<void>) {
    const movies = await crawlPopcornCollection("movies", (cursor: number) =>
        Popcorn.movies(undefined, undefined, undefined, undefined, cursor.toString())
    );
    if (movies instanceof Error) {
        await processResult(movies);
        return;
    }
    const shows = await crawlPopcornCollection<PopcornShow, PopcornShowDetail>(
        "shows",
        (cursor: number) => Popcorn.shows(undefined, undefined, undefined, undefined, cursor.toString()),
        (show: PopcornShow) => Popcorn.show(show._id)
    );
    if (shows instanceof Error) return await processResult(shows);
    else await processResult({ movies: movies.allItems, shows: shows.allTransformedItems });
}

export class PopcornMoviesSearchStream extends Stream<PopcornMovie> {
    constructor(query?: string, sort?: PopcornMovieSort, order?: PopcornOrder, genre?: PopcornGenre) {
        super(async (cursor?: string) => {
            if (!cursor) cursor = "1";
            const movies = await Popcorn.movies(query, sort, order, genre, cursor);
            if (movies instanceof Error) return movies;
            return { items: movies, cursor: (Number.parseInt(cursor) + 1).toString() };
        });
    }
    getItemKey(item: PopcornMovie): string {
        return item._id;
    }
    getItemDate(item: PopcornMovie): Date {
        return new Date(item.released * 1000);
    }
}

export class PopcornShowsSearchStream extends Stream<PopcornShow> {
    constructor(query?: string, sort?: PopcornShowSort, order?: PopcornOrder, genre?: PopcornGenre) {
        super(async (cursor?: string) => {
            if (!cursor) cursor = "1";
            const movies = await Popcorn.shows(query, sort, order, genre, cursor);
            if (movies instanceof Error) return movies;
            return { items: movies, cursor: (Number.parseInt(cursor) + 1).toString() };
        });
    }
    getItemKey(item: PopcornShow): string {
        return item._id;
    }
    getItemDate(item: PopcornShow): Date {
        return new Date();
    }
}
