export interface Messages {
    "Invalid stream": string;
    "Sorry, an unknown error occured": string;
    "End of list": string;
    "Search legal stuff...": string;
    Magnet: string;
    "A pretty good torrent search app": string;
    "(You need an app that can open magnet links)": string;
    "Popular Movies": string;
    "Popular TV Shows": string;
    Today: string;
    Week: string;
    "Top 100": string;
    Movies: string;
    "TV Shows": string;
    Anything: string;
    Login: string;
    Tasks: string;
    Files: string;
    Media: string;
}

const english: Messages = {
    "Invalid stream": "Invalid stream",
    "Sorry, an unknown error occured": "Sorry, an unknown error occured",
    "End of list": "End of list",
    "Search legal stuff...": "Search legal stuff...",
    Magnet: "Magnet",
    "A pretty good torrent search app": "A pretty good torrent search app",
    "(You need an app that can open magnet links)": "(You need an app that can open magnet links)",
    "Popular Movies": "Popular Movies",
    "Popular TV Shows": "Popular TV Shows",
    Today: "Today",
    Week: "Week",
    "Top 100": "Top 100",
    Movies: "Movies",
    "TV Shows": "TV Shows",
    Anything: "Anything",
    Login: "Login",
    Tasks: "Tasks",
    Files: "Files",
    Media: "Media",
};

export type LanguageCode = "en";

const translations: Record<LanguageCode, Messages> = {
    en: english,
};

export function i18n<T extends keyof Messages>(key: T): Messages[T] {
    const userLocale = navigator.language || (navigator as any).userLanguage;
    const languageCode = userLocale ? (userLocale.split("-")[0] as LanguageCode) : "en";
    const implementation = translations[languageCode];
    const message = implementation ? implementation[key] : translations["en"][key];
    if (!message) {
        console.error("Unknown i18n string " + key);
        return key as any as Messages[T];
    }
    return message;
}
