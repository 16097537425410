import { Api } from "./api.js";
import { PopcornMovie, PopcornShowDetail, PopcornShowEpisode, PopcornTorrent } from "./apis/popcorn.js";
import { Synology, SynologyDownloadStationTask, SynologyFile } from "./apis/synology.js";
import { State } from "./utils/state.js";
import { Store } from "./utils/store.js";
import { error, getYear } from "./utils/utils.js";

export function parseMediaMetadata(path: string): { name: string; season?: string; year: string; quality: string; id: string } | undefined {
    const segments = path.split("/");
    const re = /^(.*?)\s*(?:\[(S\d+E\d+)\])?\s*(?:\[(\d{4})\])?\s*(?:\[(\d+p)\])?\s*(?:\[(.*?)\])?$/;

    let name: string | undefined;
    let season: string | undefined;
    let year: string | undefined;
    let quality: string | undefined;
    let id: string | undefined;

    for (const segment of segments) {
        const matches = re.exec(segment);
        if (matches && matches.length > 1) {
            name = matches[1];
            season = matches[2];
            year = matches[3];
            quality = matches[4];
            id = matches[5];
        }
        if (name && year && quality && id) break;
    }

    if (!name || !year || !quality || !id) return undefined;
    return { name, season, year, quality, id };
}

export function getMediaId(movieOrShow: PopcornMovie | { show: PopcornShowDetail; episode: PopcornShowEpisode }, torrent: PopcornTorrent) {
    let metadata: string | undefined;
    if ("show" in movieOrShow) {
        const show = movieOrShow;
        metadata = `${show.show.title} [S${show.episode.season.toString().padStart(2, "0")}E${show.episode.episode
            .toString()
            .padStart(2, "0")}][${getYear(show.episode.first_aired)}][${torrent.quality}][${show.show._id}]`;
    } else {
        const movie = movieOrShow;
        metadata = `${movie.title} [${movie.year}][${torrent.quality}][${movie._id}]`;
    }
    return metadata.replace(/[^\w\-/. \[\]]/gu, "");
}

interface AppStateObjects {
    task: SynologyDownloadStationTask;
    media: SynologyFile;
}

class AppState extends State<AppStateObjects> {
    async login(synology: string, user: string, password: string) {
        const login = await Api.login(synology, user, password);
        if (login instanceof Error) return login;
        Store.setAuth(login);
        return login;
    }

    async tryLogin() {
        let auth = Store.getAuth();
        if (!auth) return false;
        if (await Api.validate(auth)) {
            await this.setup();
            return true;
        }

        const synoAuth = await Api.login(auth.synology, auth.user, auth.password);
        if (synoAuth instanceof Error) {
            Store.setAuth(undefined);
            auth = undefined;
            return false;
        } else {
            auth = synoAuth;
            Store.setAuth(auth);
            await this.setup();
            return true;
        }
    }

    async logout() {
        const auth = Store.getAuth();
        if (auth) await Api.logout(auth);
        Store.setAuth(undefined);
    }

    async getTasks() {
        const auth = Store.getAuth();
        if (!auth) return error("Not logged in");
        const tasks = await Synology.listDownloadTasks(auth);
        if (tasks instanceof Error) return tasks;
        const oldTasks = new Map<string | symbol, SynologyDownloadStationTask>(this.getAll("task"));
        tasks.forEach((task) => {
            const id = task.additional.detail.destination.replace(`${Store.getSynologyPrefs()?.downloadsFolder.substring(1)}/`, "");
            this.update("task", task, id);
            if (oldTasks) {
                oldTasks.delete(id);
            }
        });
        for (const id of oldTasks.keys()) {
            this.delete("task", id);
        }
        return tasks;
    }

    async getMedia(): Promise<Error | { media: SynologyFile[]; lookup: Map<string, SynologyFile> }> {
        const auth = Store.getAuth();
        const prefs = Store.getSynologyPrefs();
        if (!auth) return error("Not logged in");
        if (!prefs) return error("Unkown downloads folder");
        const files = await Synology.listFilesRecursive(auth, prefs.downloadsFolder);
        if (files instanceof Error) return files;

        let media = files
            .filter((file) => !file.path.startsWith("."))
            .filter((file) => !file.path.includes("@eaDir"))
            .filter((file) => file.name != ".")
            .filter((file) => !(file.name && file.name.toLowerCase().includes("sample")))
            .filter((file) => !file.isDir)
            .filter((file) => file.id)
            .filter((file) => !file.path.startsWith("."));

        media = media.filter((file) => {
            const n = file.path.toLowerCase();
            return file.size > 1024 * 1024 * 100 && (n.endsWith(".mkv") || n.endsWith(".mp4") || n.endsWith(".avi") || n.endsWith(".mov"));
        });

        const lookup = new Map<string, SynologyFile>();
        for (const file of media) {
            lookup.set(file.id!, file);
        }

        const oldMedia = new Map<string | symbol, SynologyFile>(this.getAll("media"));
        media.forEach((media) => {
            const id = media.path.split("/")[0];
            this.update("media", media, id);
            if (oldMedia) {
                oldMedia.delete(id);
            }
        });
        for (const id of oldMedia.keys()) {
            this.delete("media", id);
        }

        return { media, lookup };
    }

    private async setup() {
        await Promise.all([this.getTasks(), this.getMedia()]);
        state.poll(async () => !((await this.getTasks()) instanceof Error), 1000);
        state.poll(async () => !((await this.getMedia()) instanceof Error));
    }
}

export const state = new AppState();
