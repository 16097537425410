import { LitElement, PropertyValueMap, TemplateResult, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { Synology, SynologyAuth, SynologyFile } from "../apis/synology.js";
import { Api } from "../api.js";
import { PopcornMovie, PopcornShowDetail, PopcornShowEpisode, Popcorn, PopcornTorrent } from "../apis/popcorn.js";
import { closeIcon, downloadIcon, fourKIcon, hdIcon, playIcon } from "../utils/icons.js";
import { Store } from "../utils/store.js";
import { formatBytes, isVideoFile } from "../utils/utils.js";
import { closeButton, copyTextToClipboard, dom, isMobileBrowser, renderError, renderPageShell, toast } from "../utils/ui-components.js";
import { router } from "../utils/routing.js";
import { getMediaId, state } from "../app-state.js";

export async function openMedia(file?: SynologyFile) {
    const auth = Store.getAuth();
    if (!auth || !file) return;
    const openInVLC = (url: string) => {
        const a = document.createElement("a");
        a.href = "vlc://" + url;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const token = await Synology.getStreamingServerToken(auth, Store.getSynologyPrefs()?.downloadsFolder!);
    if (token instanceof Error) {
        alert("Sorry, the streaming server seems to be down. Go to settings and install/start it.");
        return;
    }
    const running = await Synology.isStreamingServerRunning(auth, Store.getSynologyPrefs()?.downloadsFolder!);
    if (!running) {
        alert("Sorry, the streaming server seems to be down. Go to settings and install/start it.");
        return;
    }
    const url =
        Synology.getStreamingServerUrl(auth) +
        encodeURIComponent(file.path.replace(Store.getSynologyPrefs()?.downloadsFolder!, "")) +
        "?token=" +
        encodeURIComponent(token);
    if (isVideoFile(file.path) && isMobileBrowser()) {
        openInVLC(url);
    } else {
        const p = file.path.toLowerCase();
        if (p.endsWith(".mkv") || p.endsWith(".mp4") || p.endsWith(".avi") || p.endsWith(".mov")) {
            copyTextToClipboard(url);
            toast("Copied link to clipboard");
        } else {
            window.open(url, "_blank");
        }
    }
}

@customElement("media-view")
export class MediaView extends LitElement {
    @property()
    img?: string;

    @property()
    heading = "";

    @property()
    subHeading = "";

    @property()
    label = "";

    @property()
    click = () => {};

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    render() {
        if (!this.heading || !this.subHeading) return html`<div>Error</div>`;

        return html`<div @click=${() => this.click()} class="relative cursor-pointer flex flex-col self-start w-[166px]">
            <img src="${this.img}" class="self-center h-[250px] w-auto rounded-md" />
            <div class="flex flex-col px-2 py-1">
                <span class="text-sm font-semibold truncate">${this.heading}</span>
                <span class="text-xs truncate text-muted-fg">${this.subHeading}</span>
            </div>
            ${this.label.length > 0
                ? html`<div class="absolute top-1 left-1 p-1 bg-background rounded-md text-xs font-semibold text-primary ring-1 ring-divider">
                      ${this.label}
                  </div>`
                : nothing}
        </div>`;
    }
}

function renderTorrent(
    moviewOrShow: PopcornMovie | { show: PopcornShowDetail; episode: PopcornShowEpisode },
    torrent: PopcornTorrent,
    downloadStarted = () => {}
) {
    return html`<button
        class="flex items-center gap-1 p-1 justify-center ring-1 ring-blue-500 rounded-md"
        style="text-decoration: none"
        @click=${async () => {
            const auth = Store.getAuth();
            if (!auth) return;
            const mediaMetadata = getMediaId(moviewOrShow, torrent);
            await Synology.createDirectory(auth, Store.getSynologyPrefs()?.downloadsFolder!, mediaMetadata);
            const result = await Synology.createDownloadTask(
                auth,
                torrent.url,
                `${Store.getSynologyPrefs()?.downloadsFolder.substring(1)}/${mediaMetadata}`
            );
            if (result instanceof Error) {
                toast(renderError(`Couldn't create task for ${mediaMetadata}`));
            } else {
                toast(html`<div><span>Created download task</span><span class="line-clamp-1">${mediaMetadata}</span></div>`);
            }
            downloadStarted();
        }}
    >
        ${torrent.quality == "1080p" ? html`<i class="icon !w-6 !h-6 fill-blue-500">${hdIcon}</i>` : nothing}
        ${torrent.quality == "2160p" ? html`<i class="icon !w-6 !h-6 fill-blue-500">${fourKIcon}</i>` : nothing}
        <span class="text-xs font-semibold">${torrent.quality}</span>
    </a>`;
}

export function renderTorrents(
    movieOrShow: PopcornMovie | { show: PopcornShowDetail; episode: PopcornShowEpisode },
    torrents: any,
    downloadStarted = () => {}
) {
    const keysMap = new Set<string>();
    const keys = Object.keys(torrents)
        .filter((quality) => {
            if (quality == "3D") return false;
            if (quality == "0") return false;
            if (keysMap.has(quality)) return false;
            keysMap.add(quality);
            return true;
        })
        .sort((a, b) => Number.parseInt(b) - Number.parseInt(a));
    return html` <div class="flex gap-2">
        ${map(keys, (key) => {
            const torrent = torrents[key] as PopcornTorrent;
            return renderTorrent(movieOrShow, torrent, downloadStarted);
        })}
    </div>`;
}

@customElement("media-state-view")
export class MediaStateView extends LitElement {
    @property()
    media?: PopcornMovie | { show: PopcornShowDetail; episode: PopcornShowEpisode };
    @property()
    torrents?: any;
    @property()
    waitForUpdate = false;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    unsubscribe: (() => void)[] = [];
    connectedCallback(): void {
        super.connectedCallback();
        if (!this.torrents || !this.media) return;
        for (const key of Object.keys(this.torrents)) {
            const torrent = this.torrents[key] as PopcornTorrent;
            const id = getMediaId(this.media, torrent);
            this.unsubscribe.push(
                state.subscribe(
                    "media",
                    () => {
                        this.waitForUpdate = false;
                        this.requestUpdate();
                    },
                    id
                )
            );
            this.unsubscribe.push(
                state.subscribe(
                    "task",
                    () => {
                        this.waitForUpdate = false;
                        this.requestUpdate();
                    },
                    id
                )
            );
        }
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        for (const unsub of this.unsubscribe) unsub();
    }

    render() {
        if (!this.torrents || !this.media) return nothing;
        if (this.waitForUpdate) return html`<loading-spinner></loading-spinner>`;

        const torrents = this.torrents;
        const movieOrShow = this.media;
        const keysMap = new Set<string>();
        const keys = Object.keys(torrents)
            .filter((quality) => {
                if (quality == "3D") return false;
                if (quality == "0") return false;
                if (keysMap.has(quality)) return false;
                keysMap.add(quality);
                return true;
            })
            .sort((a, b) => Number.parseInt(b) - Number.parseInt(a));

        for (const key of keys) {
            const torrent = torrents[key] as PopcornTorrent;
            const id = getMediaId(movieOrShow, torrent);
            const mediaFile = state.get("media", id);
            if (mediaFile) {
                const watched = Store.wasWatched(id);
                const rootDir = Store.getSynologyPrefs()?.downloadsFolder! + "/" + mediaFile.path.split("/")[0];

                return html`<div class="mt-2 flex gap-2 items-center">
                    <button
                        class="self-start flex items-center gap-1 p-1 pr-3 justify-center ring-1 ${watched
                            ? "ring-muted-fg"
                            : "ring-green-500"} rounded-md"
                        @click=${() => {
                            Store.setWatched(id);
                            this.requestUpdate();
                            openMedia(mediaFile);
                        }}
                    >
                        <i class="icon w-6 h-6 ${watched ? "fill-muted-fg" : "fill-green-600"}">${playIcon}</i>
                        ${watched ? "Watch again" : "Play"}
                    </button>
                    <button
                        class="ml-auto"
                        @click=${() => {
                            Synology.deleteFile(Store.getAuth()!, rootDir);
                            this.waitForUpdate = true;
                        }}
                    >
                        <i class="icon w-6 h-6">${closeIcon}</i>
                    </button>
                </div>`;
            }

            const task = state.get("task", id);
            if (task) {
                return html`<div class="flex gap-2 items-center">
                    ${task.size != task.additional?.transfer.size_downloaded
                        ? html` <span
                              class="text-xs whitespace-nowrap flex gap-1 items-center ${task.additional?.transfer.speed_download == 0
                                  ? "text-red-500"
                                  : "text-green-500"}"
                              ><i class="icon !w-4 !h-4 ${task.additional?.transfer.speed_download == 0 ? "fill-red-500" : "fill-green-500"}"
                                  >${downloadIcon}</i
                              >${formatBytes(task.additional?.transfer.speed_download ?? 0)}/s</span
                          >`
                        : html`<div class="flex items-center gap-1">
                              <i class="icon !w-4 !h-4 ${task.additional?.transfer.speed_download == 0 ? "fill-red-500" : "fill-green-500"}"
                                  >${downloadIcon}</i
                              ><span class="text-red-500">Starting download</span>
                          </div>`}
                    ${task.size == task.additional?.transfer.size_downloaded && task.size != 0
                        ? html`<span class="ml-auto text-xs text-green-500 font-bold whitespace-nowrap"
                              >${(((task.additional?.transfer?.size_downloaded ?? 0) / (task.size != 0 ? task.size : 1)) * 100).toFixed(0)}%</span
                          >`
                        : html`<span class="ml-auto text-xs text-muted-fg whitespace-nowrap"
                              >${(((task.additional?.transfer?.size_downloaded ?? 0) / (task.size != 0 ? task.size : 1)) * 100).toFixed(0)}%</span
                          >`}
                    <button
                        @click=${() => {
                            Synology.deleteDownloadTask(Store.getAuth()!, task.id);
                            this.waitForUpdate = true;
                        }}
                    >
                        <i class="icon w-6 h-6">${closeIcon}</i>
                    </button>
                </div>`;
            }
        }

        return html` <div class="flex gap-2 mt-2">
            ${map(keys, (key) => {
                const torrent = torrents[key] as PopcornTorrent;
                const id = getMediaId(movieOrShow, torrent);
                return renderTorrent(movieOrShow, torrent, () => (this.waitForUpdate = true));
            })}
        </div>`;
    }
}

@customElement("synology-media")
export class SynologyMediaOverlay extends LitElement {
    @property()
    isLoading = true;

    @property()
    error?: string;

    @property()
    movies: SynologyFile[] = [];

    @property()
    shows: SynologyFile[] = [];

    moviesLookup = new Map<string, PopcornMovie>();
    showsLookup = new Map<string, PopcornShowDetail>();
    media?: { media: SynologyFile[]; lookup: Map<string, SynologyFile> };

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        const auth = Store.getAuth();
        if (!auth) return;
        const load = async () => {
            try {
                const media = await state.getMedia();
                if (media instanceof Error) {
                    this.error = media.message;
                    return;
                }
                const movies = media.media.filter((file) => !file.season);
                const movieDetails = await Api.getMovies(auth, Array.from(new Set<string>(movies.map((file) => file.id!))));
                if (movieDetails instanceof Error) {
                    this.error = "Couldn't load movie details";
                    return;
                }
                for (const movie of movieDetails) this.moviesLookup.set(movie._id, movie);

                const shows = media.media.filter((file) => file.season);
                const showDetails = await Api.getShows(auth, Array.from(new Set<string>(shows.map((file) => file.id!))));
                if (showDetails instanceof Error) {
                    this.error = "Couldn't load show details";
                    return;
                }
                for (const show of showDetails) this.showsLookup.set(show._id, show);

                this.movies = movies;
                this.shows = shows;
                this.media = media;
            } finally {
                this.isLoading = false;
            }
        };
        load();
    }

    renderMoviesRow(files: SynologyFile[]) {
        const details: PopcornMovie[] = [];

        return html`<div class="flex overflow-x-auto gap-2">
            ${map(files, (file) => {
                let img: string | undefined;
                let heading: string | undefined;
                let subHeading: string | undefined;

                const movie = this.moviesLookup.get(file.id!);
                img = movie?.images.poster;
                heading = movie?.title;
                subHeading = movie?.year;
                return html`<media-view
                    class="w-[166px]"
                    .img=${img}
                    .heading=${heading}
                    .subHeading=${subHeading}
                    .click=${() => openMedia(file)}
                ></media-view>`;
            })}
        </div>`;
    }

    renderShowsRow(files: SynologyFile[]) {
        const shows = Array.from(new Set<PopcornShowDetail>(files.map((file) => this.showsLookup.get(file.id!)!)));
        for (const show of shows) {
            show.episodes.sort((a, b) => b.first_aired - a.first_aired);
        }
        shows.sort((a, b) => b.episodes[0].first_aired - a.episodes[0].first_aired);

        return html`<div class="flex overflow-x-auto gap-2">
            ${map(shows, (show) => {
                let img: string | undefined;
                let heading: string | undefined;
                let subHeading: string | undefined;

                let newEpisodes = 0;
                let unwatched = false;
                for (const episode of show.episodes) {
                    let hasMediaFile = false;
                    for (const key of Object.keys(episode.torrents)) {
                        const torrent = episode.torrents[key] as any as PopcornTorrent;
                        const id = getMediaId({ show, episode }, torrent);
                        const mediaFile = state.get("media", id);
                        const watched = Store.wasWatched(id);
                        if (mediaFile) {
                            if (!watched) {
                                unwatched = true;
                            }
                            hasMediaFile = true;
                            break;
                        }
                    }
                    if (!hasMediaFile) newEpisodes++;
                    else break;
                }

                img = show.images.poster;
                heading = show.title;
                subHeading = show.num_seasons + " seasons";

                return html`<media-view
                    class="w-[166px]"
                    .img=${img}
                    .heading=${heading}
                    .subHeading=${subHeading}
                    .label=${newEpisodes ? "New episodes" : unwatched ? "Unwatched episodes" : ""}
                    .click=${() => {
                        router.push("/show/" + show._id, dom(html`<show-details .show=${show}></show-details>`)[0]);
                    }}
                ></media-view>`;
            })}
        </div>`;
    }

    render(): TemplateResult {
        const auth = Store.getAuth();
        if (!auth) return renderError("Not logged in to Synology");
        if (this.error) return renderError(this.error);

        if (this.isLoading) return html`<div class="flex flex-col gap-2 px-4 mt-4"><loading-spinner></loading-spinner></div>`;

        return renderPageShell(
            "Media",
            html`<div class="w-full flex flex-col px-4 mt-4">
                <h2 class="text-muted-fg text-base mb-2 mt-">Movies</h2>
                ${this.renderMoviesRow(this.movies)}
                <h2 class="text-muted-fg text-base mb-2 mt-4">Shows</h2>
                ${this.renderShowsRow(this.shows)}
            </div>`,
            false
        );
    }
}

@customElement("show-details")
export class ShowDetailOverlay extends LitElement {
    @property()
    isLoading = true;

    @property()
    show?: PopcornShowDetail;

    @property()
    error?: string;

    seasons: PopcornShowEpisode[][] = [];

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        this.load();
    }

    async load() {
        try {
            const auth = Store.getAuth();
            if (!auth) {
                this.error = "Couldn't load show";
                return;
            }
            const paths = location.pathname.split("/");
            const show = await Popcorn.show(paths[2]);
            if (show instanceof Error) {
                this.error = "Couldn't load show";
                return;
            }
            this.show = show;
            let seasons: PopcornShowEpisode[][] = [];
            if (this.show) {
                this.show.episodes.sort((a, b) => {
                    if (a.season == b.season) return b.episode - a.episode;
                    else return b.season - a.season;
                });
                for (const episode of this.show.episodes) {
                    const season = seasons[episode.season] ?? [];
                    season.push(episode);
                    seasons[episode.season] = season;
                }
            }

            let linearSeasons: PopcornShowEpisode[][] = [];
            let index = 0;
            for (const season of seasons) {
                if (season == undefined) continue;
                linearSeasons[index++] = season;
            }
            seasons = linearSeasons;
            seasons.sort((a, b) => b[0].season - a[0].season);
            for (const season of seasons) {
                season.sort((a, b) => b.episode - a.episode);
            }
            this.seasons = seasons;
        } catch (e) {
        } finally {
            this.isLoading = false;
        }
    }

    render() {
        const seasons = this.seasons;

        if (this.isLoading) return html`<div class="flex flex-col gap-2 px-4 mt-4"><loading-spinner></loading-spinner></div>`;

        return html`<div class="relative flex flex-col w-full max-w-[640px] mx-auto min-h-full">
            <div class="absolute top-0 left-0 fill-primary w-10 h-10 bg-[#111] rounded-full backdrop-blur-[8px]">${closeButton()}</div>

            ${this.show
                ? html` <img src="${this.show.images.banner}" class="object-cover w-full h-[20vh]" />
                      <div class="flex flex-col px-4">
                          <h1 class="mt-4">${this.show.title}</h1>
                          <div class="flex items-center gap-2 mt-2">
                              <span class="text-muted-fg text-sm">${this.show.num_seasons} seasons</span
                              ><span class="text-muted-fg text-sm">${this.show.episodes.length} episodes</span>
                              <span class="text-xs text-green-500">${this.show.rating.percentage}%</span>
                          </div>
                          <p class="text-sm mt-2">${this.show.synopsis}</p>
                          ${!seasons ? html`<loading-spinner></loading-spinner>` : nothing}
                          ${map(
                              seasons ?? [],
                              (season, index) =>
                                  html`<h2 class="text-muted-fg mt-4">Season ${season[0].season}</h2>
                                      <div>
                                          ${map(
                                              season,
                                              (episode, index) => html`<div class="flex flex-col mt-2 rounded-md py-2">
                                                  <span class="text-primary line-clamp-1"
                                                      >${"S" +
                                                      episode.season.toString().padStart(2, "0") +
                                                      "E" +
                                                      episode.episode.toString().padStart(0, "0") +
                                                      " - " +
                                                      episode.title}</span
                                                  >
                                                  <span class="text-muted-fg text-xs mt-1"
                                                      >${new Date(episode.first_aired * 1000).toDateString()}</span
                                                  >
                                                  <p
                                                      class="line-clamp-2 mt-2"
                                                      @click=${(ev: Event) => (ev.target as HTMLElement).classList.toggle("line-clamp-2")}
                                                  >
                                                      ${episode.overview}
                                                  </p>
                                                  <media-state-view
                                                      .media=${{ show: this.show, episode }}
                                                      .torrents=${episode.torrents}
                                                  ></media-state-view>
                                              </div>`
                                          )}
                                      </div> `
                          )}
                      </div>`
                : html`<loading-spinner></loading-spinner>`}
        </div>`;
    }
}
