import { LitElement, PropertyValueMap, TemplateResult, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { Synology, SynologyFileOrder, SynologyFileSort, SynologyFileStationFile } from "../apis/synology.js";
import { closeIcon, folderIcon } from "../utils/icons.js";
import { Store } from "../utils/store.js";
import { copyTextToClipboard, dom, isMobileBrowser, renderError, renderPageShell, toast } from "../utils/ui-components.js";
import { isVideoFile } from "../utils/utils.js";
import { router } from "../utils/routing.js";

@customElement("synology-files")
export class SynologyFilesOverlay extends LitElement {
    path = Store.getSynologyPrefs()?.downloadsFolder!;

    @property()
    sort: SynologyFileSort = "name";

    @property()
    order: SynologyFileOrder = "asc";

    @property()
    files: SynologyFileStationFile[] = [];

    @property()
    error?: string;

    @property()
    isLoading = false;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        this.load();
    }

    async load() {
        const auth = Store.getAuth();
        this.isLoading = true;
        try {
            if (!auth) {
                this.error = "Could not list files in " + this.path;
                this.files = [];
                return;
            }
            const files = await Synology.listFiles(auth, this.path, this.sort, this.order);
            if (files instanceof Error) {
                this.error = "Couldn't fetch list files " + this.path;
                this.files = [];
                return;
            }
            if (this.path.split("/").length > 2) {
                const tokens = this.path.split("/");
                tokens.pop();
                const path = tokens.join("/");
                this.files = [
                    {
                        isdir: true,
                        additional: {
                            size: 0,
                            time: {
                                atime: new Date().getTime(),
                                ctime: new Date().getTime(),
                                crtime: new Date().getTime(),
                                mtime: new Date().getTime(),
                            },
                        },
                        name: "..",
                        path,
                    },
                    ...files,
                ];
            } else {
                this.files = files;
            }
        } finally {
            this.isLoading = false;
        }
    }

    render(): TemplateResult {
        const auth = Store.getAuth();
        const title = this.path;
        if (!auth) return renderPageShell(title, renderError("Not logged in to Synology"));
        if (this.error) return renderPageShell(title, renderError(this.error));
        if (this.isLoading) return renderPageShell(title, html`<div class=""><loading-spinner></loading-spinner></div>`);
        if (this.files.length == 0) renderPageShell(title, html`<div>No files</div>`);

        return renderPageShell(
            title,
            html`<div class="max-w-[640px] mx-auto">
                ${this.files.length > 0
                    ? html`<table class="w-full table-fixed">
                          ${map(this.files, (file) => {
                              const row = dom(html`<tr class="dark:text-white cursor-pointer hover:bg-muted px-2">
                                  ${file.isdir
                                      ? html`<td class="max-w-7 w-7 pl-2">
                                                <i class="icon w-5 h-5">${folderIcon}</i>
                                            </td>
                                            <td
                                                class="truncate max-w-xs py-2"
                                                @click=${() => {
                                                    if (file.name == "..") {
                                                        router.pop();
                                                    } else {
                                                        router.push("/files", dom(html`<synology-files .path=${file.path}></synology-files>`)[0]);
                                                    }
                                                }}
                                            >
                                                <span class="pl-2">${file.name}</span>
                                            </td>`
                                      : html`<td
                                            class="truncate max-w-xs pl-2 py-2"
                                            colspan="2"
                                            @click=${async () => {
                                                const openInVLC = (url: string) => {
                                                    const a = document.createElement("a");
                                                    a.href = "vlc://" + url;
                                                    a.style.display = "none";
                                                    document.body.appendChild(a);
                                                    a.click();
                                                    document.body.removeChild(a);
                                                };

                                                const token = await Synology.getStreamingServerToken(
                                                    auth,
                                                    Store.getSynologyPrefs()?.downloadsFolder!
                                                );
                                                if (token instanceof Error) {
                                                    alert("Sorry, the streaming server seems to be down. Go to settings and install/start it.");
                                                    return;
                                                }
                                                const running = await Synology.isStreamingServerRunning(
                                                    auth,
                                                    Store.getSynologyPrefs()?.downloadsFolder!
                                                );
                                                if (!running) {
                                                    alert("Sorry, the streaming server seems to be down. Go to settings and install/start it.");
                                                    return;
                                                }
                                                const url =
                                                    Synology.getStreamingServerUrl(auth) +
                                                    file.path.replace(Store.getSynologyPrefs()?.downloadsFolder!, "") +
                                                    "?token=" +
                                                    token;
                                                if (isVideoFile(file.path) && isMobileBrowser()) {
                                                    openInVLC(url);
                                                } else {
                                                    const p = file.path.toLowerCase();
                                                    if (p.endsWith(".mkv") || p.endsWith(".mp4") || p.endsWith(".avi") || p.endsWith(".mov")) {
                                                        copyTextToClipboard(url);
                                                        toast("Copied link to clipboard");
                                                    } else {
                                                        window.open(url, "_blank");
                                                    }
                                                }
                                            }}
                                        >
                                            <span>${file.name}</span>
                                        </td>`}
                                  <td class="w-6 text-right pr-2">
                                      <div class="flex items-center">
                                          ${file.name != ".."
                                              ? html`<button
                                                    @click=${(ev: Event) => {
                                                        ev.preventDefault();
                                                        ev.stopPropagation();
                                                        row.remove();
                                                        Synology.deleteFile(auth, file.path);
                                                    }}
                                                >
                                                    <i class="icon w-5 h-5 fill-muted-fg">${closeIcon}</i>
                                                </button>`
                                              : nothing}
                                      </div>
                                  </td>
                              </tr>`)[0];
                              return row;
                          })}
                      </table>`
                    : nothing}
            </div>`
        );
    }
}
