import { PopcornMovie, PopcornShowDetail } from "./apis/popcorn.js";
import { SynologyAuth } from "./apis/synology.js";
import { JsonValue } from "./server/key-value-store.js";
import { error } from "./utils/utils.js";

function apiBaseUrl() {
    if (typeof location === "undefined") return "http://localhost:3333/api/";
    return location.href.includes("localhost") || location.href.includes("192.168.1") ? `http://${location.hostname}:3333/api/` : "/api/";
}

export function apiGet(endpoint: string) {
    return fetch(apiBaseUrl() + endpoint);
}

export function apiPost(endpoint: string, params: URLSearchParams | FormData) {
    let headers: HeadersInit = {};
    let body: string | FormData;

    if (params instanceof URLSearchParams) {
        headers = { "Content-Type": "application/x-www-form-urlencoded" };
        body = params.toString();
    } else {
        body = params;
    }

    return fetch(apiBaseUrl() + endpoint, {
        method: "POST",
        headers: headers,
        body: body,
    });
}

export function toUrlBody(params: JsonValue) {
    const urlParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key];
        const type = typeof value;
        if (type == "string" || type == "number" || type == "boolean") {
            urlParams.append(key, value.toString());
        } else if (typeof value == "object") {
            urlParams.append(key, JSON.stringify(value));
        } else {
            throw new Error("Unsupported value type: " + typeof value);
        }
    }
    return urlParams;
}

export class Api {
    static async login(synology: string, user: string, password: string): Promise<Error | SynologyAuth> {
        try {
            const result = await apiPost("login", toUrlBody({ synology, user, password }));
            if (!result.ok) throw new Error("Could not log in");
            return (await result.json()) as SynologyAuth;
        } catch (e) {
            return error("Could not log in", e);
        }
    }

    static async logout(auth: SynologyAuth): Promise<boolean> {
        try {
            const result = await apiPost("logout", toUrlBody({ sid: auth.sid }));
            if (!result.ok) throw new Error("Could not log out");
            return true;
        } catch (e) {
            error("Could not log out", e);
            return false;
        }
    }

    static async validate(auth: SynologyAuth): Promise<boolean> {
        try {
            const result = await apiPost("validate", toUrlBody({ sid: auth.sid }));
            if (!result.ok) throw new Error("Could not validate session");
            return true;
        } catch (e) {
            error("Could not validate session", e);
            return false;
        }
    }

    static async proxyJson<T>(url: string): Promise<T | Error> {
        try {
            const response = await apiGet("json?url=" + encodeURIComponent(url));
            if (!response.ok) throw Error();
            return (await response.json()) as T;
        } catch (e) {
            return error("Couldn't proxy json request to " + url, e);
        }
    }

    static async proxyText(url: string): Promise<string | Error> {
        try {
            const response = await apiGet("text?url=" + encodeURIComponent(url));
            if (!response.ok) throw Error();
            return await response.text();
        } catch (e) {
            return error("Couldn't proxy text request to " + url, e);
        }
    }

    static async proxySynology<T>(url: string, params: URLSearchParams | FormData) {
        try {
            const response = await apiPost("synology?synology=" + encodeURIComponent(url), params);
            if (!response.ok) throw Error();
            const result = await response.json();
            if (!result.success) throw Error();
            return result.data ? (result.data as T) : (result as T);
        } catch (e) {
            return error("Couldn't proxy synology request to " + url, e);
        }
    }

    static async getShows(auth: SynologyAuth, ids: string[]) {
        try {
            const params = new URLSearchParams();
            for (const id of ids) {
                params.append("id", id);
            }
            params.append("sid", auth.sid);
            const result = await apiPost("media/show", params);
            if (!result.ok) throw new Error();
            const shows = (await result.json()) as PopcornShowDetail[];
            return shows;
        } catch (e) {
            return error("Couldn't fetch shows", e);
        }
    }

    static async getMovies(auth: SynologyAuth, ids: string[]) {
        try {
            const params = new URLSearchParams();
            for (const id of ids) {
                params.append("id", id);
            }
            params.append("sid", auth.sid);
            const result = await apiPost("media/movie", params);
            if (!result.ok) throw new Error();
            const movies = (await result.json()) as PopcornMovie[];
            return movies;
        } catch (e) {
            return error("Couldn't fetch shows", e);
        }
    }
}
