import { LitElement, PropertyValueMap, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import {
    PopcornOrder,
    PopcornMovieSort,
    PopcornShowSort,
    PopcornGenre,
    PopcornMoviesSearchStream,
    PopcornShowsSearchStream,
} from "../apis/popcorn.js";
import { i18n } from "../utils/i18n.js";
import { searchIcon, closeIcon } from "../utils/icons.js";
import { dom, renderChrome } from "../utils/ui-components.js";
import { router } from "../utils/routing.js";

@customElement("search-page")
export class SearchPage extends LitElement {
    @property()
    query = "";

    @property()
    type: "movies" | "tv" = "movies";

    @property()
    order: PopcornOrder = "desc";

    @property()
    movieSort: PopcornMovieSort = "trending";

    @property()
    showSort: PopcornShowSort = "trending";

    @property()
    genre: PopcornGenre = "all";

    constructor() {
        super();
        if (location.search.length > 0) {
            const params = location.search.split("?")[1];
            const url = new URL("https://localhost/?" + params);
            this.type = (url.searchParams.get("t") as "movies" | "tv") ?? "movies";
            this.query = url.searchParams.get("q") ?? "";
            this.order = (url.searchParams.get("o") as PopcornOrder) ?? "desc";
            this.movieSort = (url.searchParams.get("ms") as PopcornMovieSort) ?? "trending";
            this.showSort = (url.searchParams.get("ss") as PopcornShowSort) ?? "updated";
            this.genre = (url.searchParams.get("g") as PopcornGenre) ?? "all";
        }
    }

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        this.querySelector<HTMLInputElement>("#query")!.value = this.query;
        this.handleSearch();
        this.querySelector<HTMLInputElement>("#query")!.focus();
        document.title = "mtorrent - search";
    }

    render() {
        const movieSortValues: { label: string; value: PopcornMovieSort }[] = [
            { label: "Title", value: "title" },
            { label: "Newest", value: "last_added" },
            { label: "Rating", value: "rating" },
            { label: "Trending", value: "trending" },
            { label: "Year", value: "year" },
        ];
        const showSortValues: { label: string; value: PopcornShowSort }[] = [
            { label: "Title", value: "name" },
            { label: "Newest", value: "updated" },
            { label: "Rating", value: "rating" },
            { label: "Trending", value: "trending" },
            { label: "Year", value: "year" },
        ];
        const orderValues: { label: string; value: PopcornOrder }[] = [
            { label: "Ascending", value: "asc" },
            { label: "Descending", value: "desc" },
        ];
        const genreValues: { label: string; value: PopcornGenre }[] = [
            { label: "All", value: "all" },
            { label: "Action", value: "action" },
            { label: "Adventure", value: "adventure" },
            { label: "Animation", value: "animation" },
            { label: "Comedy", value: "comedy" },
            { label: "Crime", value: "crime" },
            { label: "Disaster", value: "disaster" },
            { label: "Documentary", value: "documentary" },
            { label: "Drama", value: "drama" },
            { label: "Eastern", value: "eastern" },
            { label: "Family", value: "family" },
            { label: "Fan-Film", value: "fan-film" },
            { label: "Fantasy", value: "fantasy" },
            { label: "Film-Noir", value: "film-noir" },
            { label: "History", value: "history" },
            { label: "Holiday", value: "holiday" },
            { label: "Horror", value: "horror" },
            { label: "Indie", value: "indie" },
            { label: "Music", value: "music" },
            { label: "Mystery", value: "mystery" },
            { label: "None", value: "none" },
            { label: "Road", value: "road" },
            { label: "Romance", value: "romance" },
            { label: "Science-Fiction", value: "science-fiction" },
            { label: "Short", value: "short" },
            { label: "Sports", value: "sports" },
            { label: "Sporting-Event", value: "sporting-event" },
            { label: "Suspense", value: "suspense" },
            { label: "Thriller", value: "thriller" },
            { label: "TV-Movie", value: "tv-movie" },
            { label: "War", value: "war" },
            { label: "Western", value: "western" },
        ];

        return renderChrome(html`
            <div class="fixed z-10 bottom-2 w-full max-w-[640px] -ml-4 md:ml-0 md:static flex items-center justify-center gap-4 text-blue-600 dark:text-white">
            <button class="px-4 h-10 border border-blue-600 bg-background rounded-full" @click=${() => router.push("/tasks")}>${i18n(
            "Tasks"
        )}</button>
        <button class="px-4 h-10 border border-blue-600 bg-background rounded-full" @click=${() => router.push("/media")}>${i18n("Media")}</button>
        <button class="px-4 h-10 border border-blue-600 bg-background  rounded-full" @click=${() => router.push("/files")}>${i18n("Files")}</button>
            </div>
            <div class="search fancy-shadow flex gap-4">
                <i class="icon !w-6 !h-6 fill-muted-fg">${searchIcon}</i>
                <input
                    @input=${() => this.handleSearch()}
                    id="query"
                    class="flex-grow bg-transparent"
                    autocomplete="off"
                    placeholder="${i18n("Search legal stuff...")}"
                />
                <i class="icon !w-6 !h-6 fill-muted-fg" @click=${() => {
                    this.querySelector<HTMLInputElement>("#query")!.value = "";
                    this.handleSearch();
                }}>${closeIcon}</i>
            </div>
            <div class="self-center"><button-group id="type" .values=${[
                { label: i18n("Movies"), value: "movies" },
                { label: i18n("TV Shows"), value: "tv" },
            ]}
            .selected=${this.type}
            .change=${(value: "movies" | "tv") => {
                this.type = value;
                this.handleSearch();
            }}></button-group></div>
            <div class="mx-auto flex items-center gap-2">

            <div class="flex flex-col md:flex-row md:gap-2 items-center">
                <label>Sort</label><select-box .values=${this.type == "movies" ? movieSortValues : showSortValues} .selected=${
            this.type == "movies" ? this.movieSort : this.showSort
        } .change=${(value: PopcornMovieSort | PopcornShowSort) => {
            if (this.type == "movies") this.movieSort = value as PopcornMovieSort;
            else this.showSort = value as PopcornShowSort;
            this.handleSearch();
        }}></select-box>
            </div>

            <div class="flex flex-col md:flex-row md:gap-2 items-center">
            <label>Order</label><select-box .values=${orderValues} .selected=${this.order} .change=${(value: PopcornOrder) => {
            this.order = value;
            this.handleSearch();
        }}></select-box>
        </div>

        <div class="flex flex-col md:flex-row md:gap-2 items-center">
                <label>Genre</label><select-box .values=${genreValues} .selected=${this.genre} .change=${(value: PopcornGenre) => {
            this.genre = value;
            this.handleSearch();
        }}></select-box>
        </div>

            </div>
            <loading-spinner id="spinner" class="hidden"></loading-spinner>
            <div id="results" class="flex flex-col"></div>
        </div>`);
    }

    timeoutId = 0;
    handleSearch() {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(async () => {
            const query = this.querySelector<HTMLInputElement>("#query")!.value;
            const spinnerElement = this.querySelector<HTMLElement>("#spinner")!;
            spinnerElement.classList.remove("hidden");
            await this.search(query, this.type, this.movieSort, this.showSort, this.order, this.genre);
            spinnerElement.classList.add("hidden");
        }, 200) as any as number;
    }

    async search(
        query: string,
        type: "movies" | "tv",
        moviewSort?: PopcornMovieSort,
        showSort?: PopcornShowSort,
        order?: PopcornOrder,
        genre?: PopcornGenre
    ) {
        query = query.trim();
        router.replaceUrl(`/search?t=${this.type}&q=${query}&ms=${this.movieSort}&ss=${this.showSort}&o=${this.order}&g=${this.genre}`);
        const resultsElement = this.querySelector<HTMLElement>("#results")!;
        resultsElement.innerHTML = "";

        if (type == "movies") {
            resultsElement.append(
                dom(
                    html`<movies-stream .stream=${new PopcornMoviesSearchStream(
                        query.length == 0 ? undefined : query,
                        moviewSort,
                        order,
                        genre
                    )}></movies-streem>`
                )[0]
            );
        } else {
            resultsElement.append(
                dom(
                    html`<shows-stream .stream=${new PopcornShowsSearchStream(
                        query.length == 0 ? undefined : query,
                        showSort,
                        order,
                        genre
                    )}></shows-streem>`
                )[0]
            );
        }
    }
}
