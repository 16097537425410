import { LitElement, PropertyValueMap, TemplateResult, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { Synology, SynologyDownloadStationTask } from "../apis/synology.js";
import { closeIcon, downloadIcon } from "../utils/icons.js";
import { Store } from "../utils/store.js";
import { formatBytes } from "../utils/utils.js";
import { renderError, renderPageShell, toast } from "../utils/ui-components.js";

@customElement("synology-tasks")
export class SynologyTasksOverlay extends LitElement {
    @property()
    tasks: SynologyDownloadStationTask[] = [];

    @property()
    error?: string;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        const auth = Store.getAuth();
        if (!auth) return;
        const updateTasks = async () => {
            if (!auth) return;
            const tasks = await Synology.listDownloadTasks(auth);
            if (tasks instanceof Error) {
                this.error = "Couldn't fetch tasks";
                return;
            }
            this.tasks = tasks;
            if (!this.isConnected) return;
            setTimeout(updateTasks, 1000);
        };
        updateTasks();
    }

    render(): TemplateResult {
        const auth = Store.getAuth();
        const title = "Download Tasks";
        if (!auth) return renderPageShell(title, renderError("Not logged in to Synology"));
        if (this.error) return renderPageShell(title, renderError(this.error));

        const deleteTask = async (task: SynologyDownloadStationTask) => {
            if (!auth) return;
            const result = await Synology.deleteDownloadTask(auth, task.id);
            if (result instanceof Error) {
                toast(renderError(`Couldn't delete task ${task.title}`));
            } else {
                toast(html`<div class="flex flex-col w-full"><span>Deleted task</span><span class="line-clamp-1">${task.title}</span></div>`);
            }
        };

        return renderPageShell(
            title,
            html`
            ${
                this.tasks.length == 0
                    ? html`<div class="mt-1 flex flex-col gap-2 px-4 mt-4 items-center"><span class="text-muted-fg">No download tasks</span></div>`
                    : ""
            }
            ${
                this.tasks.length > 0
                    ? html`<div class="mt-1 flex flex-col gap-2 px-4">
                          ${map(
                              this.tasks,
                              (task) => html`<div class="flex flex-col gap-2 rounded-md ring-blue-500 ring-1 dark:text-white px-4 py-2 gap-1">
                                  <div class="flex items-center">
                                      <span class="max-h-5 text-sm line-clamp-1 font-semibold">${task.title}</span>
                                      <button
                                          class="ml-auto -mr-3 flex items-center justify-center w-10 h-5"
                                          @click=${(ev: Event) => deleteTask(task)}
                                      >
                                          <i class="icon !w-5 !h-5 fill-muted-fg">${closeIcon}</i>
                                      </button>
                                  </div>
                                  <div class="flex gap-2">
                                      <span
                                          class="${task.size != task.additional?.transfer.size_downloaded
                                              ? ""
                                              : "hidden"} text-xs whitespace-nowrap flex gap-1 items-center ${task.additional?.transfer
                                              .speed_download == 0
                                              ? "text-red-500"
                                              : "text-green-500"}"
                                          ><i
                                              class="icon !w-4 !h-4 ${task.additional?.transfer.speed_download == 0
                                                  ? "fill-red-500"
                                                  : "fill-green-500"}"
                                              >${downloadIcon}</i
                                          >${formatBytes(task.additional?.transfer.speed_download ?? 0)}/s</span
                                      >
                                      ${task.size == task.additional?.transfer.size_downloaded && task.size != 0
                                          ? html`<span class="ml-auto text-xs text-green-500 font-bold whitespace-nowrap"
                                                >${(
                                                    ((task.additional?.transfer?.size_downloaded ?? 0) / (task.size != 0 ? task.size : 1)) *
                                                    100
                                                ).toFixed(0)}%</span
                                            >`
                                          : html`<span class="ml-auto text-xs text-muted-fg whitespace-nowrap"
                                                >${(
                                                    ((task.additional?.transfer?.size_downloaded ?? 0) / (task.size != 0 ? task.size : 1)) *
                                                    100
                                                ).toFixed(0)}%</span
                                            >`}
                                  </div>
                              </div>`
                          )}
                      </div>`
                    : nothing
            }
        </div>`
        );
    }
}
