import { Api, toUrlBody } from "../api.js";
import { Store } from "../utils/store.js";
import { error } from "../utils/utils.js";

export type SynologyAuth = {
    synology: string;
    user: string;
    password: string;
    sid: string;
    uid: string;
};

export type SynologyDownloadStationTask = {
    id: string;
    size: number;
    status: number;
    title: string;
    type: string;
    username: string;
    additional: {
        transfer: {
            downloaded_pieces: number;
            size_downloaded: number;
            size_uploaded: number;
            speed_download: number;
            speed_upload: number;
        };
        detail: {
            completed_time: number;
            connected_leechers: number;
            connected_peers: number;
            connected_seeders: number;
            create_time: number;
            destination: string;
            seedelapsed: number;
            started_time: number;
            total_peers: number;
            total_pieces: number;
            unzip_password: string;
            uri: string;
            waiting_seconds: number;
        };
    };
};

export type SynologyFileStationFile = {
    path: string;
    name: string;
    isdir: boolean;
    additional: {
        size: number;
        time: {
            atime: number;
            crtime: number;
            ctime: number;
            mtime: number;
        };
    };
};

export type SynologyTask = {
    action: string;
    app_name: string;
    can_delete: boolean;
    can_edit: boolean;
    can_run: boolean;
    enable: boolean;
    id: number;
    name: string;
    next_trigger_time: string;
    owner: string;
    real_owner: string;
    type: string;
};

export type SynologyTaskResult = {
    script_in: string;
    script_out: string;
};

export type SynologyShare = {
    isdir: boolean;
    name: string;
    path: string;
    additional: {
        real_path: string;
    };
};

export type SynologyFile = {
    path: string;
    size: number;
    isDir: boolean;
    name: string;
    season?: number;
    episode?: number;
    year?: string;
    quality?: string;
    id?: string;
    createTime: number;
};

export type SynologyFileSort = "name" | "size" | "user" | "group" | "mtime" | "atime" | "ctime" | "crtime" | "posix" | "type";
export type SynologyFileOrder = "asc" | "desc";

export class Synology {
    static async login(synology: string, user: string, password: string): Promise<Error | SynologyAuth> {
        if (!synology.endsWith("/")) synology += "/";

        try {
            const loginUrl = synology + "webapi/auth.cgi";
            const loginParams = new URLSearchParams();
            loginParams.append("api", "SYNO.API.Auth");
            loginParams.append("version", "6");
            loginParams.append("method", "login");
            loginParams.append("account", user);
            loginParams.append("passwd", password);
            loginParams.append("enable_syno_token", "no");
            loginParams.append("format", "sid");

            const loginData = await Api.proxySynology<SynologyAuth>(loginUrl, loginParams);
            if (loginData instanceof Error) throw loginData;

            const userUrl = synology + "webapi/entry.cgi";
            const userParams = new URLSearchParams();
            userParams.append("api", "SYNO.Core.User");
            userParams.append("method", "get");
            userParams.append("version", "1");
            userParams.append("name", user);
            userParams.append("_sid", loginData.sid);

            const userData = await Api.proxySynology<any>(userUrl, userParams);
            if (userData instanceof Error) throw userData;
            const uid = userData.users[0]?.uid;
            if (!uid) throw new Error("Couldn't get user id");
            return { ...loginData, uid, synology, user, password };
        } catch (e) {
            return error("Couldn't login to Synology", e);
        }
    }

    static async logout(auth: SynologyAuth) {
        return await Api.proxySynology<any>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                api: "SYNO.API.Auth",
                method: "logout",
                _sid: auth.sid,
            })
        );
    }

    static async checkAuth(auth: SynologyAuth): Promise<Error | boolean> {
        return !((await Synology.listShares(auth)) instanceof Error);
    }

    static async listShares(auth: SynologyAuth): Promise<Error | SynologyShare[]> {
        const result = await Api.proxySynology<any>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                api: "SYNO.FileStation.List",
                version: "1",
                method: "list_share",
                additional: `real_path`,
                _sid: auth.sid,
            })
        );
        if (result instanceof Error) return result;
        return result.shares as SynologyShare[];
    }

    static async apiInfo(auth: SynologyAuth): Promise<Error | any> {
        return await Api.proxySynology<any>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                api: "SYNO.API.Info",
                version: "1",
                method: "query",
                query: "all",
                _sid: auth.sid,
            })
        );
    }

    static async listDownloadTasks(auth: SynologyAuth) {
        const response = await Api.proxySynology<{ task: SynologyDownloadStationTask[] }>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                api: "SYNO.DownloadStation2.Task",
                version: "1",
                method: "list",
                limit: "-1",
                additional: `["transfer","detail"]`,
                _sid: auth.sid,
            })
        );
        if (response instanceof Error) return response;
        return response.task;
    }

    static async createDownloadTask(auth: SynologyAuth, magnet: string, destination: string = `""`) {
        const response = await Api.proxySynology<any>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                api: "SYNO.DownloadStation2.Task",
                version: "2",
                method: "create",
                type: "url",
                create_list: "false",
                destination,
                url: magnet,
                _sid: auth.sid,
            })
        );
        if (response instanceof Error) return response;
        return true;
    }

    static async deleteDownloadTask(auth: SynologyAuth, taskId: string) {
        const response = await Api.proxySynology<any>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                api: "SYNO.DownloadStation2.Task",
                version: "2",
                method: "delete",
                id: taskId,
                _sid: auth.sid,
            })
        );
        if (response instanceof Error) return response;
        return true;
    }

    static async resolveRealPath(auth: SynologyAuth, path: string): Promise<string | Error> {
        if (path.startsWith("/")) path = path.substring(1);
        const shares = await Synology.listShares(auth);
        if (shares instanceof Error) return shares;
        const shareName = path.split("/")[0];
        const shareRealPath = shares.find((share) => share.name == shareName);
        if (!shareRealPath) return new Error("Couldn't find share for path " + path);
        const pathElements = path.split("/");
        pathElements.shift();
        return shareRealPath.additional.real_path + (pathElements.length > 0 ? "/" + pathElements?.join("/") : "");
    }

    static async listFiles(auth: SynologyAuth, path: string, sort: SynologyFileSort, order: SynologyFileOrder) {
        const response = await Api.proxySynology<{ files: SynologyFileStationFile[] }>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                api: "SYNO.FileStation.List",
                version: "2",
                method: "list",
                folder_path: path,
                additional: `["size","time"]`,
                _sid: auth.sid,
            })
        );
        if (response instanceof Error) return response;
        return response.files;
    }

    static async listFilesRecursive(auth: SynologyAuth, path: string) {
        const token = await this.getStreamingServerToken(auth, path);
        const url = this.getStreamingServerUrl(auth);
        if (token instanceof Error) return token;
        try {
            return await Api.proxyJson<SynologyFile[]>(url + "?token=" + token);
        } catch (e) {
            return error("Couldn't list files recursively");
        }
    }

    static async uploadFile(auth: SynologyAuth, folder: string, filename: string, content: ArrayBuffer): Promise<Error | void> {
        const blob = new Blob([content]);

        const urlParams = new URLSearchParams();
        urlParams.append("api", "SYNO.FileStation.Upload");
        urlParams.append("method", "upload");
        urlParams.append("version", "2");
        urlParams.append("_sid", auth.sid);
        const url = `${auth.synology}webapi/entry.cgi?` + urlParams;

        // Prepare the FormData
        const formData = new FormData();
        formData.append("mtime", new Date().getTime().toString());
        formData.append("overwrite", "true");
        formData.append("path", `${folder}`);
        formData.append("size", content.byteLength.toString());
        formData.append("file", blob, filename);
        formData.append("create_parents", "true");

        return await Api.proxySynology<any>(url, formData);
    }

    static async downloadTextFile(auth: SynologyAuth, path: string): Promise<Error | string> {
        const url = `${auth.synology}webapi/entry.cgi?`;

        // Prepare the FormData
        const formData = new URLSearchParams();
        formData.append("api", "SYNO.FileStation.Download");
        formData.append("method", "download");
        formData.append("version", "2");
        formData.append("_sid", auth.sid);
        formData.append("path", path);
        formData.append("mode", "download");

        return await Api.proxyText(url + formData.toString());
    }

    static async deleteFile(auth: SynologyAuth, path: string): Promise<Error | void> {
        const formData = new URLSearchParams();
        formData.append("api", "SYNO.FileStation.Delete");
        formData.append("method", "start");
        formData.append("version", "2");
        formData.append("path", '["' + path + '"]');
        formData.append("accurate_progress", "true");
        formData.append("_sid", auth.sid);
        const url = `${auth.synology}webapi/entry.cgi?`;

        return await Api.proxySynology(url, formData);
    }

    static async createDirectory(auth: SynologyAuth, path: string, name: String): Promise<Error | void> {
        const formData = new URLSearchParams();
        formData.append("api", "SYNO.FileStation.CreateFolder");
        formData.append("method", "create");
        formData.append("version", "2");
        formData.append("folder_path", '["' + path + '"]');
        formData.append("name", '["' + name + '"]');
        formData.append("force_parent", "true");
        formData.append("_sid", auth.sid);
        const url = `${auth.synology}webapi/entry.cgi?`;

        return await Api.proxySynology(url, formData);
    }

    static async listTasks(auth: SynologyAuth) {
        const response = await Api.proxySynology<{ tasks: SynologyTask[] }>(
            `${auth.synology}webapi/entry.cgi`,
            toUrlBody({
                sort_by: "next_trigger_time",
                sort_direction: "ASC",
                api: "SYNO.Core.TaskScheduler",
                method: "list",
                version: 2,
                _sid: auth.sid,
            })
        );
        if (response instanceof Error) return response;
        return response.tasks;
    }

    static async createTask(auth: SynologyAuth, name: string, event: "bootup" | "shutdown", cmd: string) {
        try {
            cmd = cmd.replace(/"/g, '\\"');

            const query = new URLSearchParams();
            query.append("api", "SYNO.Core.EventScheduler");
            query.append("method", "create");
            query.append("version", "1");
            query.append("_sid", auth.sid);
            const url = auth.synology + "webapi/entry.cgi?" + query.toString();

            const params = new URLSearchParams();
            params.append("task_name", `"${name}"`);
            params.append("owner", `{"1026":"${auth.user}"}`);
            params.append("event", `"${event}"`);
            params.append("enable", "true");
            params.append("depend_on_task", '""');
            params.append("notify_enable", "false");
            params.append("notify_mail", '""');
            params.append("notify_if_error", "false");
            params.append("operation_type", '"script"');
            params.append("operation", `"${cmd}"`);

            const response = await Api.proxySynology<any>(url, params);
            if (response instanceof Error) throw response;
            const tasks = await this.listTasks(auth);
            if (tasks instanceof Error) throw tasks;
            const task = tasks.find((task) => task.name == name);
            if (!task) throw new Error("Couldn't find task details");
            return task;
        } catch (e) {
            return error("Couldn't create task", e);
        }
    }

    static async runTask(auth: SynologyAuth, name: string): Promise<Error | SynologyTaskResult> {
        try {
            const url = auth.synology + "webapi/entry.cgi";

            const params = new URLSearchParams();
            params.append("stop_when_error", "false");
            params.append("mode", '"sequential"');
            params.append("compound", `[{"api":"SYNO.Core.EventScheduler","method":"run","version":1,"task_name":"${name}"}]`);
            params.append("api", "SYNO.Entry.Request");
            params.append("method", "request");
            params.append("version", "1");
            params.append("_sid", auth.sid);

            const response = await Api.proxySynology<{ has_fail: boolean }>(url, params);
            if (response instanceof Error) throw response;
            if (response.has_fail) throw new Error("Couldn't run task");

            const resultListParams = new URLSearchParams();
            resultListParams.append("task_name", `"${name}"`);
            resultListParams.append("api", "SYNO.Core.EventScheduler");
            resultListParams.append("method", "result_list");
            resultListParams.append("version", "1");
            resultListParams.append("_sid", auth.sid);
            const resultListResponse = await Api.proxySynology<any>(url, resultListParams);
            if (resultListResponse instanceof Error) throw resultListResponse;
            const resultId = resultListResponse[resultListResponse.length - 1].result_id;

            const resultParams = new URLSearchParams();
            resultParams.append("task_name", `"${name}"`);
            resultParams.append("result_id", resultId);
            resultParams.append("api", "SYNO.Core.EventScheduler");
            resultParams.append("method", "result_get_file");
            resultParams.append("version", "1");
            resultParams.append("_sid", auth.sid);

            const resultResponse = await Api.proxySynology<any>(url, resultParams);
            if (resultResponse instanceof Error) throw resultResponse;
            return resultResponse;
        } catch (e) {
            return error("Couldn't run task", e);
        }
    }

    static async deleteTask(auth: SynologyAuth, name: string) {
        try {
            const query = new URLSearchParams();
            query.append("api", "SYNO.Entry.Request");
            query.append("method", "request");
            query.append("version", "1");
            query.append("_sid", auth.sid);
            const url = auth.synology + "webapi/entry.cgi?" + query.toString();

            const params = new URLSearchParams();
            params.append("stop_when_error", "false");
            params.append("mode", '"sequential"');
            params.append("compound", `[{"api":"SYNO.Core.EventScheduler","method":"delete","version":1,"task_name":"${name}"}]`);

            const response = await Api.proxySynology<{ has_fail: boolean }>(url, params);
            if (response instanceof Error) throw response;
            if (response.has_fail) throw new Error("Couldn't delete task");
            return true;
        } catch (e) {
            return error("Couldn't delete task", e);
        }
    }

    static async bash(auth: SynologyAuth, cmd: string) {
        try {
            const task = "mtorrent-bash-executor";
            await Synology.deleteTask(auth, task);
            const create = await Synology.createTask(auth, task, "bootup", cmd);
            if (create instanceof Error) throw create;
            const run = await Synology.runTask(auth, task);
            if (run instanceof Error) throw run;
            await Synology.deleteTask(auth, task);
            return run.script_out;
        } catch (e) {
            return error("Couldn't run bash script", e);
        }
    }

    static getStreamingServerUrl(auth: SynologyAuth) {
        const url = new URL(auth.synology);
        url.protocol = "http";
        url.port = "7777";
        return url.toString();
    }

    static async isStreamingServerRunning(auth: SynologyAuth, downloadsFolder: string) {
        try {
            const token = await this.getStreamingServerToken(auth, downloadsFolder);
            const response = await Api.proxyText(this.getStreamingServerUrl(auth) + "?token=" + token);
            if (response instanceof Error) return false;
            return true;
        } catch (e) {
            return false;
        }
    }

    static async getStreamingServerToken(auth: SynologyAuth, downloadsFolder: string) {
        const token = await Synology.downloadTextFile(auth, downloadsFolder + "/.mtorrent/.mtorrent-token");
        if (token instanceof Error) return token;
        return token;
    }
}
