import { LitElement, PropertyValueMap, TemplateResult, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { renderError } from "../utils/ui-components.js";
import { Api } from "../api.js";
import { i18n } from "../utils/i18n.js";
import { Store } from "../utils/store.js";
import { error } from "../utils/utils.js";
import { router } from "../utils/routing.js";
import { state } from "../app-state.js";

@customElement("login-page")
export class LoginPage extends LitElement {
    @property()
    error?: string;

    @property()
    isLoading = false;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        document.title = "mtorrent - login";
    }

    render(): TemplateResult {
        document.title;
        return html`<div class="w-full max-w-[320px] mx-auto flex flex-col gap-2 px-4 pt-4">
            <h1 class="text-primary text-center">mtorrent</h1>
            ${this.error ? renderError(this.error) : nothing}
            <div class="flex flex-col text-black">
                <input
                    id="synology"
                    name="synology-url"
                    class="border border-divider px-4 py-1 rounded-md mt-4"
                    placeholder="Synology URL"
                    autocomplete="url"
                    autocapitalize="none"
                />
                <input
                    id="user"
                    class="border border-divider px-4 py-1 rounded-md"
                    placeholder="User name"
                    autocomplete="off"
                    autocapitalize="none"
                />
                <input
                    id="password"
                    type="password"
                    class="border border-divider px-4 py-1 rounded-md"
                    placeholder="Password"
                    autocapitalize="none"
                />
            </div>
            <button class="btn" @click=${() => this.handleLogin()} ?disabled=${this.isLoading}>${i18n("Login")}</button>
            <loading-spinner class="${!this.isLoading ? "hidden" : ""}"></loading-spinner>
        </div>`;
    }

    async handleLogin() {
        try {
            localStorage.removeItem("auth");
            const synology = this.querySelector<HTMLInputElement>("#synology")?.value ?? "";
            const user = this.querySelector<HTMLInputElement>("#user")?.value ?? "";
            const password = this.querySelector<HTMLInputElement>("#password")?.value ?? "";

            this.isLoading = true;
            const synoAuth = await state.login(synology, user, password);
            if (synoAuth instanceof Error) {
                throw synoAuth;
            }
            router.replace("/search");
        } catch (e) {
            error("Couldn't log in", e);
            this.error = "Couldn't log in";
        } finally {
            this.isLoading = false;
        }
    }
}
