import { LitElement, PropertyValueMap, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { Api } from "./api.js";
import { setupLiveReload } from "./utils/live-reload.js";
import { router } from "./utils/routing.js";
import { renderError } from "./utils/ui-components.js";
import { Store } from "./utils/store.js";
import { SynologyAuth, SynologyDownloadStationTask, SynologyFile } from "./apis/synology.js";
import { state } from "./app-state.js";
export * from "./pages/index.js";
export * from "./utils/ui-components.js";

setupLiveReload();

@customElement("app-main")
export class App extends LitElement {
    @property()
    isLoading = true;

    constructor() {
        super();
    }

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        router.addRoute("/", () => {
            this.tryLogin();
            return html`<loading-spinner class="mt-4"></loading-spinner>`;
        });
        router.addRoute("/404", () => renderError("Whoops, that page doesn't exist"));
        router.addRoute("/login", () => html`<login-page></login-page>`);
        router.addRoute(
            "/search",
            () => html`<search-page></search-page>`,
            true,
            () => true
        );
        router.addRoute("/settings", () => html`<mtorrent-settings></mtorrent-settings>`, true);
        router.addRoute("/tasks", () => html`<synology-tasks></synology-tasks>`, true);
        router.addRoute(
            "/files",
            () => html`<synology-files></synology-files>`,
            true,
            () => false
        );
        router.addRoute("/media", () => html`<synology-media></synology-media>`, true);
        router.addRoute("/show/:id", () => html`<show-details></show-details>`, true);

        router.setRootRoute("/login");
        router.setNotFoundRoot("/404");
        router.setAuthProvider(() => Store.getAuth() != undefined);
        this.tryLogin();
    }

    render() {
        return html`<div class="mt-12 flex flex-col gap-2"><span class="text-center">Connecting ...</span><loading-spinner></loading-spinner></div>`;
    }

    async tryLogin() {
        if (!(await state.tryLogin())) {
            router.popAll("/login");
        } else {
            router.setRootRoute("/search");
            if (location.pathname == "/") router.replace("/search");
            else router.replace(location.pathname + location.search + location.hash);
        }
        this.innerHTML = "";
    }
}
