import { LitElement, PropertyValueMap, TemplateResult, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { Api } from "../api.js";
import { Synology } from "../apis/synology.js";
import { Store } from "../utils/store.js";
import { renderPageShell } from "../utils/ui-components.js";
import { router } from "../utils/routing.js";
import { state } from "../app-state.js";

@customElement("mtorrent-settings")
export class Settings extends LitElement {
    @property()
    streamingServerStatus: "unknown" | "confirm" | "running" | "stopped" = "unknown";

    @property()
    setupLog = "";

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        this.checkStreamingServerStatus();
        document.title = "mtorrent - settings";
    }

    async checkStreamingServerStatus() {
        const auth = Store.getAuth();
        if (!auth) return;
        this.streamingServerStatus = "unknown";
        this.streamingServerStatus = (await Synology.isStreamingServerRunning(auth, Store.getSynologyPrefs()?.downloadsFolder!))
            ? "running"
            : "stopped";
    }

    render(): TemplateResult {
        return renderPageShell(
            "Settings",
            html`<div class="flex items-center p-4">
                    <div class="flex flex-col">
                        <h2 class="text-muted-fg">Logged in as</h2>
                        <span>${Store.getAuth()?.user}</span>
                    </div>
                    <button class="ml-auto btn" @click=${() => this.logout()}>Log out</button>
                </div>
                <div class="flex flex-col p-4 border-t border-divider gap-4">
                    <h2 class="text-muted-fg">Streaming Server</h2>
                    ${this.streamingServerStatus == "unknown"
                        ? html`<div class="whitespace-pre">${this.setupLog}</div>
                              <loading-spinner></loading-spinner>`
                        : this.streamingServerStatus == "confirm"
                        ? html`<div class="whitespace-pre">${this.setupLog}</div>
                              <button class="btn self-start" @click=${() => this.checkStreamingServerStatus()}>OK</button>`
                        : html` <div class="flex items-center gap-2">
                                  <span>Status: </span
                                  ><span class="${this.streamingServerStatus == "running" ? "text-green-500" : "text-red-500"}"
                                      >${this.streamingServerStatus}</span
                                  >
                              </div>
                              <div class="flex items-center gap-4">
                                  <button class="btn" @click=${() => this.startStopStreamingServer()}>
                                      ${this.streamingServerStatus == "running" ? "Stop" : "Start"}
                                  </button>
                                  ${this.streamingServerStatus == "running"
                                      ? nothing
                                      : html`<button class="btn" @click=${() => this.setupStreamingServer()}>(Re)-install</button>`}
                              </div>`}
                </div>`
        );
    }

    async logout() {
        await state.logout();
        router.setRootRoute("/login");
        router.popAll("/login");
    }

    async startStopStreamingServer() {
        const auth = Store.getAuth();
        if (!auth) return;
        switch (this.streamingServerStatus) {
            case "unknown":
                return;
            case "running": {
                this.streamingServerStatus = "unknown";
                this.setupLog = "Stopping streaming server ... ";
                const result = await Synology.bash(auth, "killall mtorrent-streaming-server-x86_64");
                if (result instanceof Error) this.setupLog += "FAILED";
                else this.setupLog += "OK\n" + result;
                this.streamingServerStatus = "confirm";
                break;
            }
            case "stopped": {
                this.streamingServerStatus = "unknown";
                this.setupLog = "Starting streaming server ... ";
                const realPath = await Synology.resolveRealPath(auth, Store.getSynologyPrefs()?.downloadsFolder!);
                const result = await Synology.bash(
                    auth,
                    `${realPath}/.mtorrent/mtorrent-streaming-server-x86_64 --dir ${realPath} --port 7777 --token ${realPath}/.mtorrent`
                );
                if (result instanceof Error) this.setupLog += "FAILED";
                else this.setupLog += "OK\n" + result;
                this.streamingServerStatus = "confirm";
                break;
            }
        }
    }

    async setupStreamingServer() {
        const auth = Store.getAuth();
        if (!auth) return;
        try {
            this.streamingServerStatus = "unknown";
            this.setupLog = "Checking if streaming server is running ... ";
            const isRunning = await Synology.isStreamingServerRunning(auth, Store.getSynologyPrefs()?.downloadsFolder!);
            this.setupLog += (isRunning ? "YES" : "NO") + "\n";
            if (!isRunning) {
                this.setupLog += "Killing orphaned processes ... ";
                await Synology.bash(auth, "killall mtorrent-streaming-server-x86_64");
                this.setupLog += "OK\n";
                this.setupLog += "Downloading streaming server ... ";
                const download = await fetch(location.protocol + "//" + location.host + "/mtorrent-streaming-server-x86_64");
                if (!download.ok) {
                    this.setupLog += "FAILED\n";
                    return;
                }
                const bytes = await download.arrayBuffer();
                this.setupLog += "OK\n";
                this.setupLog += "Installing streaming server ... ";
                const upload = await Synology.uploadFile(
                    auth,
                    `${Store.getSynologyPrefs()?.downloadsFolder}/.mtorrent`,
                    "mtorrent-streaming-server-x86_64",
                    bytes
                );
                if (upload instanceof Error) {
                    this.setupLog += "FAILED\n";
                    return;
                }
                this.setupLog += "OK\n";
                this.setupLog += "Starting streaming server ... ";
                const tasks = await Synology.listTasks(auth);
                if (tasks instanceof Error) {
                    this.setupLog += "FAILED (list)\n";
                    return;
                }
                if (tasks.find((other) => other.name == "mtorrent-streaming-server")) {
                    const deleteTask = await Synology.deleteTask(auth, "mtorrent-streaming-server");
                    if (deleteTask instanceof Error) {
                        this.setupLog += "FAILED (delete)\n";
                        return;
                    }
                }
                const realPath = await Synology.resolveRealPath(auth, Store.getSynologyPrefs()?.downloadsFolder!);
                const create = await Synology.createTask(
                    auth,
                    "mtorrent-streaming-server",
                    "bootup",
                    `${realPath}/.mtorrent/mtorrent-streaming-server-x86_64 --dir ${realPath} --port 7777 --token ${realPath}/.mtorrent`
                );
                if (create instanceof Error) {
                    this.setupLog += "FAILED (create)\n";
                    return;
                }
                const run = await Synology.runTask(auth, "mtorrent-streaming-server");
                if (run instanceof Error) {
                    this.setupLog += "FAILED (run)\n";
                    return;
                }
                this.setupLog += "OK\nMessage: " + run.script_out;
            }
        } finally {
            this.streamingServerStatus = "confirm";
        }
    }
}
