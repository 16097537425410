import { LitElement, PropertyValueMap, TemplateResult, html, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { map } from "lit/directives/map.js";
import { PopcornMovie, PopcornShowDetail, PopcornShowEpisode, PopcornTorrent, PopcornShow, Popcorn } from "../apis/popcorn.js";
import { Synology } from "../apis/synology.js";
import { hdIcon, fourKIcon, upDownIcon, playIcon, downloadIcon, closeIcon } from "../utils/icons.js";
import { Store } from "../utils/store.js";
import { StreamView } from "../utils/streamviews.js";
import { formatBytes, getTimeDifference, getYear, sleep } from "../utils/utils.js";
import { onVisibleOnce, renderError, toast } from "../utils/ui-components.js";
import { getMediaId, state } from "../app-state.js";
import { openMedia } from "./media.js";

@customElement("movies-stream")
export class PopcornMovieStreamView extends StreamView<PopcornMovie> {
    constructor() {
        super();
        this.wrapItem = false;
    }

    renderItem(item: PopcornMovie, polledItems: boolean): TemplateResult {
        const torrents = item.torrents;

        return html`<div
            class="mb-2 flex flex-col gap-2 w-full text-sm text-black dark:text-white cursor-pointer p-4 rounded-md border border-divider md:border-none"
        >
            <div class="flex flex-col md:flex-row gap-4 w-full items-center md:items-start">
                <img src="${item.images.poster.replace("http:", "https:")}" class="rounded-md max-h-[200px] w-auto" />
                <div class="flex flex-col w-full">
                    <span class="font-semibold">${item.title}</span>
                    <span class="text-muted-fg">${item.genres.join(" ")}</span>
                    <div class="flex items-center gap-2 font-semibold mb-4">
                        ${item.year ? html`<span class="text-xs text-primary">${item.year}</span>` : nothing}
                        ${item.rating?.percentage ? html`<span class="text-xs text-green-500">${item.rating.percentage}%</span>` : nothing}
                        ${item.trailer ? html`<a href="${item.trailer}" target="_blank" class="text-red-500">Trailer</a>` : nothing}
                    </div>
                    <p
                        class="line-clamp-2 md:line-clamp-6 mb-4"
                        @click=${(ev: Event) => {
                            (ev.target as HTMLElement).classList.toggle("line-clamp-2");
                        }}
                    >
                        ${item.synopsis}
                    </p>
                    ${map(Object.keys(item.torrents), (lang) => {
                        const torrents = (item.torrents as any)[lang] as any;
                        return html`<media-state-view .media=${item} .torrents=${torrents}></media-state-view>`;
                    })}
                </div>
            </div>
        </div>`;
    }
}

@customElement("episode-view")
export class EpisodeView extends LitElement {
    @property()
    episode?: PopcornShowEpisode;

    @property()
    show?: PopcornShowDetail;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    render() {
        if (!this.episode || !this.show) return nothing;
        const episode = this.episode;
        return html`<div class="flex flex-col px-4 py-4 border-b border-divider">
            <div class="flex items-center gap-2 text-primary font-semibold">
                <span>S${episode.season.toString().padStart(2, "0")}E${episode.episode.toString().padStart(2, "0")}</span>
                <span>-</span>
                <span class="line-clamp">${episode.title}</span>
                <span class="ml-auto text-muted-fg text-xs">${getTimeDifference(episode.first_aired * 1000)}</span>
            </div>
            <p class="line-clamp-2" @click=${(ev: Event) => (ev.target as HTMLElement).classList.toggle("line-clamp-2")}>${episode.overview}</p>
            <media-state-view class="mt-2" .media=${{ show: this.show, episode: this.episode }} .torrents=${episode.torrents}></media-state-view>
        </div>`;
    }
}

@customElement("episodes-view")
export class EpisodesView extends LitElement {
    @property()
    show?: PopcornShowDetail;

    @property()
    expanded = false;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    render() {
        if (!this.show) return nothing;
        return html`<div
                class="cursor-pointer flex flex-col items-center p-1 bg-muted justify-center ${!this.expanded
                    ? "rounded-md"
                    : "rounded-t-lg"} fancy-shadow"
                @click=${() => (this.expanded = !this.expanded)}
            >
                <span class="flex gap-2 items-center">${this.show.episodes.length} Episodes <i class="icon w-5 h-5">${upDownIcon}</i></span>
            </div>
            ${this.expanded
                ? html` <div class="flex flex-col border border-divider">
                      ${map(
                          [...this.show.episodes].reverse(),
                          (episode) => html`<episode-view .show=${this.show} .episode=${episode}></episode-view>`
                      )}
                  </div>`
                : nothing}`;
    }
}

@customElement("show-view")
export class PopcornShowView extends LitElement {
    @property()
    item?: PopcornShow;

    @property()
    detail?: PopcornShowDetail;

    episodes?: PopcornShowEpisode[];

    @property()
    error?: string;

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        onVisibleOnce(this, async () => {
            if (!this.item) {
                this.error = "Can not load show info";
                return;
            }
            let tries = 0;
            let sleepTime = 1000;
            while (true) {
                const show = await Popcorn.show(this.item.imdb_id);
                if (show instanceof Error) {
                    tries++;
                    if (tries < 3) {
                        await sleep(sleepTime);
                        sleepTime *= 2;
                        continue;
                    }
                    this.error = "Can not load show info";
                    return;
                }
                this.detail = show;
                this.episodes = show.episodes.sort((a, b) => {
                    if (a.season == b.season) return a.episode - b.episode;
                    else return a.season - b.season;
                });
                break;
            }
        });
    }

    render() {
        if (!this.item) return nothing;
        const item = this.item;
        const detail = this.detail;

        return html`<div
            class="mb-2 flex flex-col gap-2 w-full text-sm text-black dark:text-white p-4 rounded-md border border-divider md:border-none"
        >
            <div class="flex flex-col md:flex-row gap-4 w-full items-center md:items-start">
                <img src="${item.images.poster.replace("http:", "https:")}" class="rounded-md max-h-[200px] w-auto" />
                <div class="flex flex-col w-full">
                    <span class="font-semibold">${item.title}</span>
                    <div class="flex items-center gap-2 mb-4 font-semibold">
                        ${item.year ? html`<span class="text-xs text-primary">${item.year}</span>` : nothing}
                        ${item.rating?.percentage ? html`<span class="text-xs text-green-500">${item.rating.percentage}%</span>` : nothing}
                        <span class="text-xs text-red-500">${item.num_seasons} ${item.num_seasons == 1 ? "Season" : "Seasons"}</span>
                    </div>
                    ${detail
                        ? html` <p
                                  class="line-clamp-2 md:line-clamp-5 mb-4"
                                  @click=${(ev: Event) => {
                                      (ev.target as HTMLElement).classList.toggle("line-clamp-2");
                                  }}
                              >
                                  ${detail.synopsis}
                              </p>
                              <episodes-view .show=${this.detail}></episodes-view>`
                        : !this.error
                        ? html`<loading-spinner></loading-spinner>`
                        : renderError(this.error)}
                </div>
            </div>
        </div>`;
    }
}

@customElement("shows-stream")
export class PopcornShowsStreamView extends StreamView<PopcornShow> {
    constructor() {
        super();
        this.wrapItem = false;
    }

    renderItem(item: PopcornShow, polledItems: boolean): TemplateResult {
        return html`<show-view .item=${item}></show-view>`;
    }
}
